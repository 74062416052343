export const CHAT_JOIN = "CHAT::CHAT_JOIN";
export const CHAT_JOIN_SUCCEEDED = "CHAT::CHAT_JOIN_SUCCEEDED";
export const CHAT_JOIN_FAILED = "CHAT::CHAT_JOIN_FAILED";

export const CHAT_MESSAGES_SUBSCRIBE = "CHAT::CHAT_MESSAGES_SUBSCRIBE";
export const CHAT_MESSAGES_SUBSCRIBE_SUCCEEDED =
  "CHAT::CHAT_MESSAGES_SUBSCRIBE_SUCCEEDED";
export const CHAT_MESSAGES_SUBSCRIBE_FAILED =
  "CHAT::CHAT_MESSAGES_SUBSCRIBE_FAILED";

export const CHAT_MESSAGES_UNSUBSCRIBE = "CHAT::CHAT_MESSAGES_UNSUBSCRIBE";
export const CHAT_MESSAGES_UNSUBSCRIBE_SUCCEEDED =
  "CHAT::CHAT_MESSAGES_UNSUBSCRIBE_SUCCEEDED";
export const CHAT_MESSAGES_UNSUBSCRIBE_FAILED =
  "CHAT::CHAT_MESSAGES_UNSUBSCRIBE_FAILED";

export const CHAT_MESSAGE_SEND = "CHAT::CHAT_MESSAGE_SEND";
export const CHAT_MESSAGE_SEND_SUCCEEDED = "CHAT::CHAT_MESSAGE_SEND_SUCCEEDED";
export const CHAT_MESSAGE_SEND_FAILED = "CHAT::CHAT_MESSAGE_SEND_FAILED";

export const CHAT_MESSAGE_RECIEVED = "CHAT::CHAT_MESSAGE_RECIEVED";

export const CHAT_LEAVE = "CHAT::CHAT_LEAVE";
export const CHAT_LEAVE_SUCCEEDED = "CHAT::CHAT_LEAVE_SUCCEEDED";
export const CHAT_LEAVE_FAILED = "CHAT::CHAT_LEAVE_FAILED";

export const CHAT_ADD_MESSAGE_CLASS = "CHAT::ADD_MESSAGE_CLASS";
export const CHAT_ADD_MESSAGE_CLASS_SUCCEEDED =
  "CHAT::ADD_MESSAGE_CLASS_SUCCEEDED";
export const CHAT_ADD_MESSAGE_CLASS_FAILED = "CHAT::ADD_MESSAGE_CLASS_FAILED";

export const CHAT_SPECIAL_MESSAGE_RECIEVED =
  "CHAT::CHAT_SPECIAL_MESSAGE_RECIEVED";

export const CHAT_UNSUBSCRIBE = "CHAT::CHAT_UNSUBSCRIBE";

export const UPDATE_READ_MESSAGE_COUNT = "CHAT::UPDATE_READ_MESSAGE_COUNT";

export const SET_UNREAD_MESSAGE_COUNT = "CHAT::SET_UNREAD_MESSAGE_COUNT";
export const RESET_UNREAD_CHAT_MESSAGE_COUNTER =
  "CHAT::RESET_UNREAD_CHAT_MESSAGE_COUNTER";
export const OPEN_PRIVATE_CHAT_WINDOW = "CHAT::OPEN_PRIVATE_CHAT_WINDOW";
