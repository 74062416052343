import React,{useEffect, useRef} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import * as callActionCreators from "store/actions/call";
import * as devicesActionCreators from "store/actions/devices";
import * as appActionCreators from "store/actions/app";
import { Spinner } from "@blueprintjs/core";
import axios from "axios";
import "./WaitingScreen.scss";

const mapStateToProps = ({ call,config }) => ({
  extData: config.extData,
  extDataType: config.extDataType,
  portal: config.urlPortal.value,
  callEndTime : call.callEndTime,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(callActionCreators, dispatch),
  ...bindActionCreators(devicesActionCreators, dispatch),
  ...bindActionCreators(appActionCreators, dispatch),
});

const WaitingScreen = ({portal,extData,extDataType,callEndTime,initFailed,resetHardwareCheckDevicesState,rejoinCall }) => {
  const { t } = useTranslation();
  const interval = useRef(0);//
 
  useEffect(()=>{
    interval.current = (15000- (Date.now() - callEndTime))>0?(15000- (Date.now() - callEndTime)) : 0;
    if(extData && extDataType){
      rejoinCall();
      resetHardwareCheckDevicesState();
      window.setTimeout(()=>{
      axios.post(
        `https://${portal.replace('https://','').replace('http://','')}/api/epic/v1/room/map`,
        {extData, extDataType},
        {headers: {"Content-Type": "application/json"}}
      ).then((res) => {
        const invocationParameters = res.data;
        console.log(
          `Received room information: ${JSON.stringify(
            invocationParameters
          )}`
        );
        if (!invocationParameters) {
          console.error(`Empty room information`);
          throw new Error('Empty room information');
        }
        const newUrlParams = Object.keys(invocationParameters || {})
          .map((key) => {
            if (key === "invitees") {
              invocationParameters[key] = btoa(
                JSON.stringify(invocationParameters[key])
              );
            }
            if (key === "token") {
              return `sessionToken=${invocationParameters[key]}`
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              invocationParameters[key]
            )}`;
          })
          .join("&")
          .concat("&", `portal=${portal}`)
          .concat("&", `extDataType=${extDataType}`)
          .concat("&", `extData=${extData}`);
  
          const newUrl = `${
          window.location.href.split("?")[0]
        }?${newUrlParams}`; 
        window.location.replace(newUrl);
      })
      .catch((err) => {
        console.error(`Error during receiving room information: ${err}`);
        initFailed();
      });
    },interval.current)
    }
    // eslint-disable-next-line
  },[])


  return (
    <div className="leaving-call-screen">
      <div role="alert" aria-live="assertive" className="content">
        {t("PLEASE_WAIT")}
        <div className="initial-loader">
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingScreen);
