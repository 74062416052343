import React from "react";
import "./BrowserIcon.scss";

function BrowserIcon({ title, link, src }) {
  return (
    <a
      href={link}
      className="browser-link"
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      <div className="browser-link__logo">
        <img src={src} alt={title} title={title} />
      </div>
      <div className="browser-link__title">{title}</div>
    </a>
  );
}

export default BrowserIcon;
