import React from "react";
import { getInitials, test } from "utils/helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import groupChatIcon from "../../../../assets/images/buttons/in-call-details.svg";
import "./ParticipantsListItem.scss";

const ParticipantsListItem = ({ unsafeName, participantItemClick, item  }) => {
  const unreadMessageCounter =
    useSelector((state) => state.chat.unreadMessageCount[item.id]) || null;
  const { t } = useTranslation();
  const itemClick = () => {
    participantItemClick(item);
  };
  return (
    <div
      className="chat-list participant-list-item"
      {...test("PARTICIPANT")}
      onClick={itemClick}
    >
      <div className="person-container">
        <div aria-hidden className="avatar" {...test("PARTICIPANT_AVATAR")}>
          {item.id === "group" ? (
            <img src={groupChatIcon} alt="" className="avatar-img"></img>
          ) : (
            getInitials(unsafeName)
          )}
        </div>
        {unreadMessageCounter ? (
          <div
            role="alert"
            aria-live="polite"
            aria-relevant="all"
            tabIndex="-1"
            aria-label={t("UREAD_MESSAGES") + " " + unreadMessageCounter}
            className="unread-message-counter"
          >
            <span aria-hidden>
              {unreadMessageCounter < 10 ? unreadMessageCounter : "9+"}
            </span>
          </div>
        ) : null}
      </div>

      <div tabIndex="0" className="details-container">
        <div className="details-column">
          <div className="name" {...test("PARTICIPANT_NAME")}>
            {unsafeName}
          </div>
          <div className="additional-info">
            {item.isLeft && (
              <div className="text moderator">{t("CHAT_LEFT_THE_CONFERENCE")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ParticipantsListItem);
