import {
  CHAT_LEAVE_SUCCEEDED,
  CHAT_MESSAGE_RECIEVED,
  CHAT_MESSAGE_SEND_SUCCEEDED,
  CHAT_SPECIAL_MESSAGE_RECIEVED,
  RESET_UNREAD_CHAT_MESSAGE_COUNTER,
  OPEN_PRIVATE_CHAT_WINDOW,
} from "../actions/types/chat";

const initialState = {
  history: { group: [] },
  unreadMessageCount: {},
  specialMessage: null,
  messageRecieved: null,
  openPrivateChatWindowFor: null,
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_MESSAGE_SEND_SUCCEEDED: {
      let tempHistory = { ...state.history };
      let payload = action.payload;
      if (!tempHistory[payload.participant.id]) {
        tempHistory[payload.participant.id] = [];
      }
      tempHistory[payload.participant.id] = [
        ...tempHistory[payload.participant.id],
        payload,
      ];
      return {
        ...state,
        history: { ...state.history, ...tempHistory },
      };
    }
    case CHAT_MESSAGE_RECIEVED: {
      let tempHistory = { ...state.history };
      let payload = action.payload;
      const channelID = payload.channelID;
      if (!tempHistory[channelID]) {
        tempHistory[channelID] = [];
      }
      tempHistory[channelID] = [...tempHistory[channelID], payload];
      return {
        ...state,
        history: { ...state.history, ...tempHistory },
        unreadMessageCount: {
          ...state.unreadMessageCount,
          [channelID]: state.unreadMessageCount[channelID]
            ? ++state.unreadMessageCount[channelID]
            : 1,
        },
        messageRecieved: payload,
      };
    }

    case CHAT_LEAVE_SUCCEEDED:
      return {
        ...state,
        history: { group: [] },
        unreadMessageCount: {},
        messageRecieved: null,
      };

    case CHAT_SPECIAL_MESSAGE_RECIEVED:
      return {
        ...state,
        specialMessage: action.payload,
      };

    case RESET_UNREAD_CHAT_MESSAGE_COUNTER: {
      return {
        ...state,
        unreadMessageCount: action.payload
          ? {
              ...state.unreadMessageCount,
              [action.payload]: 0,
            }
          : {},
      };
    }

    case OPEN_PRIVATE_CHAT_WINDOW: {
      return {
        ...state,
        openPrivateChatWindowFor: action.payload,
      };
    }

    default:
      return state;
  }
};

export default chat;
