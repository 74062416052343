import { useState, useEffect } from "react";
import storage from "utils/storage";
import { isSafari } from "react-device-detect";

const params = new URLSearchParams(window.location.search);
const effectParam =
  params.get("blur") || window.appConfig.REACT_APP_BACKGROUND_EFFECT_ENABLED;

export default function useBackgroundEffect() {
  const [backgroundEffectAvailable, setBackgroundEffectAvailable] = useState(
    window.banubaPluginReady
  );

  useEffect(() => {
    const onBanubaPluginReady = () => {
      setBackgroundEffectAvailable(true);
    };
    window.addEventListener("BanubaPluginReady", onBanubaPluginReady, false);
    return () => {
      window.removeEventListener("BanubaPluginReady", onBanubaPluginReady);
    };
  }, [setBackgroundEffectAvailable]);

  return [backgroundEffectAvailable];
}

let isBackgroundEffectSupported = effectParam && !isSafari;
export const getIsBackgroundEffectSupported = () => isBackgroundEffectSupported;
export const modifyBackgroundEffectSupport = (isSapported = true) => {
  isBackgroundEffectSupported = effectParam && isSapported;
};

export const applyDefaultPortalEffect = async (defaultPortalBackground) => {
  if (storage.getItem("defaultPortalBackground")) {
    storage.removeItem("defaultPortalBackground");
    storage.removeItem("selectedCameraEffect");
    if (window.banuba?.clearEffect) await window.banuba?.clearEffect();
  }

  if (
    !storage.getItem("selectedCameraEffect") &&
    !storage.getItem("clearCameraEffect") &&
    defaultPortalBackground
  ) {
    if (defaultPortalBackground === "NONE") {
      storage.removeItem("selectedCameraEffect");
      storage.addItem("defaultPortalBackground", true);
    } else if (defaultPortalBackground === "BLUR") {
      if (window.banuba?.applyEffect) await window.banuba.applyEffect("blur");
      storage.addItem("selectedCameraEffect", { id: "blur" });
      storage.addItem("defaultPortalBackground", true);
    } else if (defaultPortalBackground.indexOf("IMAGE_") === 0) {
      let index = parseInt(defaultPortalBackground.replace(/[a-z_]*/i, ""));

      // For now, there are predefined 7 images
      if (index > 0 && index < 8) {
        if (window.banuba?.applyEffect) await window.banuba.applyEffect(`camera_bg_${index}`);
        storage.addItem("selectedCameraEffect", {
          id: `predefinedImage${index}`,
          name: `camera_bg_${index}`,
          preview: `images/camera_bg_preview/camera_bg_${index}.jpg`,
          label: `Image ${index}`,
        });
        storage.addItem("defaultPortalBackground", true);
      } else {
        console.warn(
          "Couldn't apply background image from portal. Image from portal don't match images in effect folder."
        );
      }
    }
  }
};
