import React, { useEffect, useRef, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { unsafeParseTextFromHTMLString } from "utils/helpers";
import { useLanguageDirection } from "utils/hooks";
import * as callActionCreators from "store/actions/call";
import ParticipantsListItem from "./ParticipantsListItem/ParticipantsListItem";
import { Button } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";

import "./ParticipantsList.scss";

const mapState = ({ call }, props) => {
  return {
    participants: call.participants,
    props: props,
    participantsDetails: call.participants.detailedList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(callActionCreators, dispatch),
});

const ParticipantsList = ({
  participants,
  participantItemClick,
  participantUpdated,
  isChatOpen
}) => {
  const languageDirection = useLanguageDirection();
  const GROUP_CHAT_ITEM = useMemo(() => {
    return { name: "GROUP_CHAT", id: "group" };
  }, []);
  const leftParticipantList = useRef([]);
  const [participantList, setParticipantList] = useState([]);
  const { t } = useTranslation();
  const BREAKOUT_ROOM = useSelector((state) => state.feature_breakoutRooms);
  const history = useSelector(({ chat }) => chat.history) || [];
  const unreadMessageCounter =
    useSelector((state) => state.chat.unreadMessageCount) || null;

  useEffect(() => {
    const id = participants.participantLeft?.id;
    const messageLength = history[id]?.length || 0;
    if (!BREAKOUT_ROOM.isTransferInProgress && participants.participantLeft && !participants.participantLeft.isLocal) {
      participants.participantLeft["isLeft"] = true;
      if(messageLength >0){
      leftParticipantList.current = [
        ...leftParticipantList.current,
        participants.participantLeft,
      ];
    }
      participantUpdated(participants.participantLeft);
    }
    // eslint-disable-next-line
  }, [participants.participantLeft]);

  useEffect(() => {
    if(!isChatOpen && document.getElementById("search-box").value !== ""){
      document.getElementById("search-box").value = "";
      searchParticipant("");
    }
  
    // eslint-disable-next-line
  }, [isChatOpen]);

  useEffect(() => {
    if (participants.participantJoined) {
      participantUpdated(participants.participantJoined);
    }
    // eslint-disable-next-line
  }, [participants.participantJoined]);

  useEffect(() => {
    leftParticipantList.current = onlyInLeft(leftParticipantList.current,participants.list);
    leftParticipantList.current.forEach(item => item["isLeft"] =true);
    const list = [
      GROUP_CHAT_ITEM,
      ...participants.list,
      ...leftParticipantList.current,
    ];
    setParticipantList(list);
    // eslint-disable-next-line
  }, [participants.list, GROUP_CHAT_ITEM]);

  useEffect(() => {
    if (BREAKOUT_ROOM.isTransferInProgress) {
      leftParticipantList.current = [];
      const list = [
        GROUP_CHAT_ITEM,
        ...participants.list,
        ...leftParticipantList.current,
      ];
      setParticipantList(list);
    }
  }, [participants.list, GROUP_CHAT_ITEM, BREAKOUT_ROOM.isTransferInProgress]);

  useEffect(() => {
    if (unreadMessageCounter) {
      const unReadMessageParticipantList = participants.list.filter((item)=>{
        return unreadMessageCounter[item.id]>0;
      })
      const participantPresentList = onlyInLeft(participants.list,unReadMessageParticipantList);
      const list = [GROUP_CHAT_ITEM,...unReadMessageParticipantList,...participantPresentList,...leftParticipantList.current]
      setParticipantList(list);
    }
  // eslint-disable-next-line
  }, [unreadMessageCounter,participants.list]);



  const searchParticipant = (name) => {
    let updatedList = [
      GROUP_CHAT_ITEM,
      ...participants.list,
      ...leftParticipantList.current,
    ];
    updatedList = updatedList.filter((item) => {
      return t(item.name).toLowerCase().indexOf(name.toLowerCase()) !== -1;
    });
    setParticipantList(updatedList);
  };

  const filterBySearch = (event) => {
    const searchText = event.target.value;
    searchParticipant(searchText);
  };


  // A comparer used to determine if two entries are equal.
  const isSameUser = (a, b) => a.id === b.id;

  // Get items that only occur in the left array,
  // using the compareFunction to determine equality.
  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(leftValue =>
      !right.some(rightValue =>
        isSameUser(leftValue, rightValue)));

  return (
    <>
      <div className="participants-list-container" dir={languageDirection}>
        <div className="search-header-container">
          <div className="search-header">
            <div className={"search-icon" + (document.getElementById("search-box")?.value === "" ? " deactive" : "")}></div>
            <input
              id="search-box"
              onChange={filterBySearch}
              autoComplete="off"
              placeholder={t("PARTICIPANT_SEARCH_PLACEHOLDER")}
            />
            {document.getElementById("search-box")?.value !== "" && (
              <Button
                aria-label={`${t("CLEAR_SEARCH")}`}
                className="mobile-chat-toggle"
                onClick={() => {
                  searchParticipant("");
                  document.getElementById("search-box").value = "";
                }}
              />
            )}
          </div>
        </div>
        <div className="participants-list-content">
          {participantList.map((item) => {
            if (item.isLocal) {
              return null;
            }
            let unsafeName = unsafeParseTextFromHTMLString(t(item.name));

            return (
              <ParticipantsListItem
                unsafeName={unsafeName}
                participantItemClick={participantItemClick}
                key={item.id}
                item={item}
             />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default connect(
  mapState,
  mapDispatchToProps
)(React.memo(ParticipantsList));
