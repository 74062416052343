import React from "react";
import { useTranslation } from "react-i18next";

function OSUnsupported() {
  const { t } = useTranslation();

  return (
    <>
      <p className="browser-support__title">
        <strong>{t("OS_NOT_SUPPORTED")}</strong>
      </p>
    </>
  );
}

export default OSUnsupported;
