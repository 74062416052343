export const UPDATE_USER = "USER::UPDATE_USER";
export const SET_USER = "USER::SET_USER";
export const SET_USER_SUCCEEDED = "USER::SET_USER_SUCCEEDED";
export const SET_USER_FAILED = "USER::SET_USER_FAILED";
export const RESET_USER = "USER::RESET_USER";

export const LOGOUT = "USER::LOGOUT";
export const LOGOUT_SUCCEEDED = "USER::LOGOUT_SUCCEEDED";
export const LOGOUT_FAILED = "USER::LOGOUT_FAILED";

export const MODERATION_STATUS_UPDATE = "USER::MODERATION_STATUS_UPDATE";
