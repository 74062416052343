export const TRANSFER_STARTED = "BR_ROOMS_FEATURE::TRANSFER_STARTED";
export const TRANSFER_FINISHED = "BR_ROOMS_FEATURE::TRANSFER_FINISHED";
export const TRANSFER_FAILED = "BR_ROOMS_FEATURE::TRANSFER_FAILED";

export const SET_TRANSFER_STATUS = "BR_ROOMS_FEATURE::SET_TRANSFER_STATUS";

export const END_CALL_FOR_BR_TRANSFER =
  "BR_ROOMS_FEATURE::END_CALL_FOR_BR_TRANSFER";

export const SET_MAIN_ROOM_NAME = "BR_ROOMS_FEATURE::SET_MAIN_ROOM_NAME";
export const SET_MAIN_ROOM_ID = "BR_ROOMS_FEATURE::SET_MAIN_ROOM_ID";

export const SET_IS_SHOW_AFTER_MOOVING_NOTIFICATION =
  "BR_ROOMS_FEATURE::SET_IS_SHOW_AFTER_MOOVING_NOTIFICATION";

export const SET_IS_ROOM_BREAKOUT = "BR_ROOMS_FEATURE::SET_IS_ROOM_BREAKOUT";

export const SET_IS_SHOW_LEFT_DIALOG =
  "BR_ROOMS_FEATURE::SET_IS_SHOW_LEFT_DIALOG";

export const RAISE_HAND = "BR_ROOMS_FEATURE::RAISE_HAND";
export const RAISE_HAND_SUCCEEDED = "BR_ROOMS_FEATURE::RAISE_HAND_SUCCEEDED";
export const RAISE_HAND_FAILED = "BR_ROOMS_FEATURE::RAISE_HAND_FAILED";

export const UNRAISE_HAND = "BR_ROOMS_FEATURE::UNRAISE_HAND";
export const UNRAISE_HAND_SUCCEEDED =
  "BR_ROOMS_FEATURE::UNRAISE_HAND_SUCCEEDED";
export const UNRAISE_HAND_FAILED = "BR_ROOMS_FEATURE::UNRAISE_HAND_FAILED";

export const SET_IS_RAISE_HAND_BUTTTON_ACTIVE =
  "BR_ROOMS_FEATURE::SET_IS_RAISE_HAND_BUTTTON_ACTIVE";

export const SET_IS_RAISE_HAND_REQUEST_IN_PROGRESS =
  "BR_ROOMS_FEATURE::SET_IS_RAISE_HAND_REQUEST_IN_PROGRESS";
