import axios from "axios";

export async function fetchData(url, settings) {
  const resp = await axios({
    url,
    ...settings,
  });

  return resp;
}
