import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Classes } from "@blueprintjs/core";

import { setMirrorSelfView } from "store/actions/devices";
import MicrophoneSelectList from "./selects/MicrophoneSelectList";
import SpeakerSelectList from "./selects/SpeakerSelectList";
import CameraSelectList from "./selects/CameraSelectList";
import SelfView from "containers/SelfView";
import "./MediaTab.scss";
import { Stethoscope } from "features";
import CameraEffectsSelector from "containers/CameraEffectsSelector";
import * as googleAnalytics from "store/actions/googleAnalytics";
import storage from "utils/storage";

const MediaTab = () => {
  const { t } = useTranslation();
  const [showSelfView, setShowSelfView] = useState(true);
  const { urlInitializeWebView } = useSelector((state) => state.config);
  const mirrorSelfView = useSelector(
    (state) => state.devices["mirrorSelfView"]
  );
  const dispatch = useDispatch();

  const onShowSelfView = useCallback(
    (value) => {
      setShowSelfView(value);
    },
    [setShowSelfView]
  );

  const mirrorSelfViewCheckbox = () => {
    return (
      <>
        {
          <div className={"mirror-self-view-checkbox"}>
            <Checkbox
              checked={mirrorSelfView}
              onClick={() => {
                storage.addItem("mirrorSelfView", !mirrorSelfView);
                dispatch(setMirrorSelfView(!mirrorSelfView));
                dispatch(googleAnalytics.mirrorSelfViewClick("settings"));
              }}
              className={Classes.INTENT_PRIMARY}
              label={t("MIRROR_SELF_VIEW")}
            />
          </div>
        }
      </>
    );
  };

  return (
    <div className="settings-tab-content media-tab-content">
      <div tabIndex="-1" className="settings-tab-content-header">
        {t("SETTINGS_AUDIO_VIDEO")}
      </div>
      <div className="settings-tab-content-body">
        <div className="tab-content-body-panel left-pane">
          <MicrophoneSelectList />
          <SpeakerSelectList />
          <Stethoscope.SelectList />
        </div>
        <div className="tab-content-body-panel right-pane">
          {!urlInitializeWebView.value && (
            <>
              {<CameraSelectList />}
              {mirrorSelfViewCheckbox()}
              {showSelfView && <SelfView ignoreMuteState={true} />}
              {<CameraEffectsSelector showSelfView={onShowSelfView} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaTab;
