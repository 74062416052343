import React, { useEffect, useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainLogoWhite from "components/MainLogoWhite";
import { useHTMLMessageFormatting, useLanguageDirection } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { browserNotSupported, osNotSupported, isIOS15 } from "utils/browserSupport";
import {
  isAndroid,
  isIOS,
  isMobile,
  deviceDetect,
  isSafari,
} from "react-device-detect";
import { tcData } from "utils/constants";
import { fetchData } from "utils/axios";
import * as googleAnalytics from "store/actions/googleAnalytics";
import { test } from "utils/helpers";
import Alert from "components/Alert/Alert";
import APIClient from "services/APIClient";
import { Spinner } from "@blueprintjs/core";
import OSUnsupported from "./unsupportedBlocks/OSUnsupported/OSUnsupported";
import BrowserUnsupported from "./unsupportedBlocks/BrowserUnsupported/BrowserUnsupported";
import DownloadIcon from "../../assets/images/icons/download-icon.svg";
import QuestionIcon from "../../assets/images/icons/question-icon.svg";
import IconLink from "../../components/UnSupported/LinkWithIcon/LinkWithIcon";
import "./BrowserCheckScreen.scss";

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(googleAnalytics, dispatch),
});

const BrowserCheckScreen = ({ browserIsNotSupported }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const languageDirection = useLanguageDirection();
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState("");
  const [joinLink, setJoinLink] = useState("");
  const [waitForParams, setWaitForParams] = useState(true);
  const [ios15message, setIOS15message] = useState(null);

  const continueAnyway = () => {
    navigate("/InitialScreen");
  };

  const urlParams = new URLSearchParams(window.location.search);
  const portalURL = urlParams.get("portal");
  const roomKey = urlParams.get("roomKey");
  const extData = urlParams.get("extData");
  const extDataType = urlParams.get("extDataType");

  const howToSetupLink = isMobile
    ? "https://www.allinahealth.org/virtual-visit/how-to-join-a-virtual-visit-on-mobile-vidyo-download"
    : "https://www.allinahealth.org/virtual-visit/how-to-join-a-virtual-visit-on-desktop-vidyo-download";

  const [formatMessage] = useHTMLMessageFormatting();

  const getPortalData = useCallback(async () => {
    let url = "";

    if (isMobile) {
      if (extDataType && extData) {
        url = `${portalURL}/mobile.html?key=${roomKey}&extDataType=${extDataType}&extData=${extData}`;
      } else {
        url = `${portalURL}/mobile.html?key=${roomKey}`;
      }
    } else {
      if (extDataType && extData) {
        url = `${portalURL}/join/?extDataType=${extDataType}&extData=${extData}`;
      } else {
        url = `${portalURL}/join/${roomKey}`;
      }
    }

    return fetchData(url, {
      method: "get",
      timeout: tcData.SERVER_TIMEOUT,
    });
  }, [portalURL, roomKey, extDataType, extData]);

  const applyPortalData = useCallback(
    ({ data }) => {
      const downloadAppPath = isSafari
        ? data.split('window.location = "/upload/')[1]?.split('";')[0]
        : data.split("window.location = '/upload/")[1]?.split("';")[0];
      const downloadDesktopAppUrl = downloadAppPath
        ? `${portalURL}/upload/${downloadAppPath}`
        : "";
      const joinLinkDesktop =
        data
          .split(
            `${
              isSafari
                ? 'window.location = "'
                : 'ifr.contentWindow.location = "'
            }`
          )[1]
          ?.split('";')[0] || "";
      const joinLinkMobile =
        data.split('var joinLink = "')[1]?.split(";")[0]?.split('"')[0] || "";

      if (isMobile) {
        setJoinLink(joinLinkMobile);
        if (isIOS) {
          setDownloadLink(
            "itms-apps://itunes.apple.com/us/app/vidyo-neo/id1103823278?ls=1&amp;mt=8"
          );
        } else if (isAndroid) {
          setDownloadLink(
            "https://play.google.com/store/apps/details?id=com.vidyo.neomobile"
          );
        }
      } else {
        setDownloadLink(downloadDesktopAppUrl);
        setJoinLink(joinLinkDesktop);
      }

      setIsLoading(false);
    },
    [portalURL]
  );

  useEffect(() => {
    let isMounted = true;

    getPortalData()
      .then((data) => {
        if (isMounted) {
          applyPortalData(data);
        }
      })
      .catch(function (error) {
        console.log(`Fetch portal error: ${error}`);
      });

    return () => {
      isMounted = false;
    };
  }, [applyPortalData, getPortalData]);

  const downloadHandler = () => setDownloadClicked(true);

  useEffect(() => {
    if (isIOS15) {
      const searchParams = new URLSearchParams(window.location.search);
      const portal = decodeURIComponent(searchParams.get("portal") || "");

      if (!portal) {
        console.error("No portal adress for sending customParams request");
        setWaitForParams(false);
        return undefined;
      }

      APIClient.getCustomParameters({ host: portal })
        .then((res) => {
          const ios15message = res?.unregistered?.ios15message;

          if (ios15message) {
            setIOS15message(ios15message);
          } else {
            setWaitForParams(false);
          }
        })
        .catch((e) => {
          console.error("Error while getting custom parameters", e);
          setWaitForParams(false);
        });
    }
    return () => {
      if (
        isIOS15 &&
        sessionStorage.getItem("isIOS15PopupShown") &&
        window.location.search.includes("sessionToken")
      ) {
        sessionStorage.removeItem("isIOS15PopupShown");
      }
    };
  }, []);

  if (window.location.protocol !== "https:") {
    return (window.location.protocol = "https:");
  }

  if (osNotSupported || browserNotSupported) {
    browserIsNotSupported(JSON.stringify(deviceDetect()));

    const boxClassName = isLoading
      ? "browser-support browser-support--loading"
      : "browser-support";

    return (
      <div className={boxClassName} {...test("BROWSER_NOT_SUPPORTED_SCREEN")}>
        <div className="browser-support__box" dir={languageDirection}>
          {isLoading ? (
            <div className="browser-support__loader">
              <MainLogoWhite />
              <Spinner />
            </div>
          ) : (
            <>
              <MainLogoWhite />
              <div className="browser-support__content">
                {osNotSupported ? <OSUnsupported /> : <BrowserUnsupported />}
                <div className="browser-support__question">
                  <p>
                    <strong>{t("HAVE_INSTALLED_APP")}</strong>
                  </p>
                  <a href={joinLink} className="browser-support__btn">
                    {t("LAUNCH_VIDYOCONNECT")}
                  </a>
                  {downloadClicked ? (
                    <p className="browser-support__launch-info">
                      {t("ONCE_YOU_DOWNLOAD_APP")}
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="browser-support__sep">
                    <strong>{t("HAVE_NOT_INSTALLED_APP")}</strong>
                  </p>
                </div>
                <div className="browser-support__setup">
                  <IconLink
                    link={downloadLink}
                    src={DownloadIcon}
                    title={t("DOWNLOAD_VIDYOCONNECT_APP")}
                    clickHandler={downloadHandler}
                  />
                  <IconLink
                    link={howToSetupLink}
                    src={QuestionIcon}
                    title={t("HOW_TO_SETUP_VIDYOCONNECT")}
                  />
                </div>
                <p className="browser-support__secure">
                  {t("SECURE_PARTNER_INFO")}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  if (
    isIOS15 &&
    waitForParams &&
    !sessionStorage.getItem("isIOS15PopupShown")
  ) {
    if (ios15message) {
      // temporary save the flag to avoid showing IOS 15 pop up twice.
      // The second time it heppend after we receive invocation params from a portal and refresh the page
      sessionStorage.setItem("isIOS15PopupShown", true);
    }
    return (
      <div
        className="browser-not-supported-screen"
        {...test("BROWSER_NOT_SUPPORTED_SCREEN")}
      >
        {ios15message ? (
          <div className="content" dir={languageDirection}>
            <MainLogoWhite />
            <Alert
              isOpen={true}
              onConfirm={continueAnyway}
              className="iosAlert"
              message={{
                header: t("WARNING"),
                text: formatMessage(ios15message),
              }}
              buttonText={t("OK")}
            />
          </div>
        ) : (
          <div className="spinner-box">
            <Spinner className="bp5-intent-white" />
          </div>
        )}
      </div>
    );
  }

  return <Navigate replace to={"/InitialScreen"} />;
};

export default connect(null, mapDispatchToProps)(BrowserCheckScreen);
