import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { test } from "utils/helpers";
import { Classes } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import hotkeys from "hotkeys-js";
import { getShortcutKeys, keyShortcutsLog } from "utils/keyboardShortcuts";
import * as googleAnalytics from "store/actions/googleAnalytics";

import "./Toggle.scss";

const Toggle = ({ isChatOpen, onClick, label }) => {
  const unreadMessageCounter = useSelector(
    (state) => state.chat.unreadMessageCount
  );

  const chatButtonToggle = useSelector(
    (state) =>
      state.config.portalFeatures?.["EndpointPublicChat"] &&
      (state.config.urlChat.isDefault || state.config.urlChat.value)
  );
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  hotkeys.filter = function (event) {
    return true;
  };

  useEffect(() => {
    hotkeys(getShortcutKeys().TOOGLE_CHAT_PANEL, (e, handler) => {
      if (!chatButtonToggle) {
        return null;
      }
      e.preventDefault();
      onClick();
      keyShortcutsLog(`Key pressed - ${handler.key}`);
      dispatch(googleAnalytics.keyboardShortcuts("TOGGLE_CHAT_PANEL"));
    });

    return () => {
      hotkeys.unbind(getShortcutKeys().TOOGLE_CHAT_PANEL);
    };
  }, [onClick, chatButtonToggle, dispatch]);

  useEffect(() => {
    let unreadMessage = 0;
    for (const id in unreadMessageCounter) {
      unreadMessage += unreadMessageCounter[id];
    }
    setUnreadMessageCount(unreadMessage);
  }, [unreadMessageCounter]);

  if (!chatButtonToggle) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={"chat-button-container " + Classes.POPOVER_DISMISS}>
        <button
          aria-label={`${t("CHAT")} ${t("TOGGLE")}`}
          aria-expanded={isChatOpen ? "true" : "false"}
          className={`chat-toggle${isChatOpen ? " active" : ""}`}
          {...test("CHAT_BUTTON")}
          onClick={onClick}
        >
          {unreadMessageCount && !label ? (
            <div
              role="alert"
              aria-live="polite"
              aria-relevant="all"
              tabIndex="-1"
              aria-label={t("UREAD_MESSAGES") + " " + unreadMessageCount}
              className="unread-message-counter"
            >
              <span aria-hidden>
                {unreadMessageCount < 10 ? unreadMessageCount : "9+"}
              </span>
            </div>
          ) : null}
        </button>
        {label && <span onClick={onClick}>{label}</span>}
        {unreadMessageCount && label ? (
          <div
            role="alert"
            aria-live="polite"
            aria-relevant="all"
            tabIndex="-1"
            aria-label={t("UREAD_MESSAGES") + " " + unreadMessageCount}
            className="unread-message-counter"
          >
            <span aria-hidden>
              {unreadMessageCount < 10 ? unreadMessageCount : "9+"}
            </span>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Toggle;
