import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendChatMessage } from "store/actions/chat";
import {
  Button,
  Classes,
  Position,
  Tooltip,
  TextArea,
} from "@blueprintjs/core";
import { isIOS, isAndroid } from "react-device-detect";
import sendIcon from "assets/images/buttons/send.svg";
import sendIconActive from "assets/images/buttons/input_send_active.svg";
import { test, getFormattedString } from "utils/helpers";
import { useTranslation } from "react-i18next";
import "./MessageComposer.scss";

const MAX_MESSSAGE_LENGTH = 1024;

const MessageComposer = (props) => {
  const [message, setMessage] = useState("");
  const [isOpenToolTip, setOpenToolTip] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const BREAKOUT_ROOM = useSelector((state) => state.feature_breakoutRooms);
  const onChange = (event) => {
    var newMessageLength = event.target.value.length;
    if (newMessageLength <= MAX_MESSSAGE_LENGTH) {
      setOpenToolTip(false);
      setMessage(event.target.value);
    } else {
      setOpenToolTip(true);
      let txtText = event.target.value.substring(0, MAX_MESSSAGE_LENGTH);
      setMessage(txtText);
    }
  };

  const onPaste = (event) => {
    let pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    var newMessageLength = pasteText.length;
    if (newMessageLength > MAX_MESSSAGE_LENGTH) {
      event.preventDefault();
      setOpenToolTip(true);
    }
  };

  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      dispatch(
        sendChatMessage({
          message: trimmedMessage,
          participant: props.participant,
        })
      );
      setMessage("");
      setOpenToolTip(false);
      document.getElementById(props.inputId)?.focus();
      window.setTimeout(()=>{
      if (isIOS || isAndroid) {
        document.getElementById(props.inputId)?.scrollIntoView();
        resetChatHeaderLocation();
      }
    },10);
    }
  };

  const resetChatHeaderLocation = (reset)=>{
    window.setTimeout(()=>{
      if(Math.round(document.body.clientHeight-window.visualViewport.height)!==0){
        document.getElementsByClassName("chat-header")[0].style.position = "absolute";
        document.getElementsByClassName("chat-header")[0].style.top = (window.visualViewport.pageTop) + `px`;
      }else{
        document.getElementsByClassName("chat-header")[0].style.position = "unset";
      }
    },300)
  }
  const onKeyDown = function (event) {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  useEffect(() => {
    if (
      !BREAKOUT_ROOM.isTransferInProgress &&
      BREAKOUT_ROOM.isShowAfterMovingNotification
    ) {
      setMessage("");
      setOpenToolTip(false);
    }
  }, [
    BREAKOUT_ROOM.isShowAfterMovingNotification,
    BREAKOUT_ROOM.isTransferInProgress,
  ]);

  const handleKeyboardChange=()=>{
    if ((isIOS || isAndroid)) {
      resetChatHeaderLocation();
    }
  }

  const handleOrentationChange=()=>{
    window.setTimeout(() => {
    if ((isIOS || isAndroid)) {
      
      document.getElementById(props.inputId)?.blur();
    }
  }, 10);
  }

  useEffect(() => {
    window.visualViewport.addEventListener("resize", handleKeyboardChange);
    window.screen.orientation.addEventListener("change", handleOrentationChange)
    return () => {
      if ((isIOS || isAndroid)) {
      
          resetChatHeaderLocation();
        }
      window.visualViewport.removeEventListener("resize", handleKeyboardChange);
      window.screen.orientation.removeEventListener("change",handleOrentationChange);
       
    }
    // eslint-disable-next-line
  }, [
    ]);

  return (
    <React.Fragment>
      <div className="message-composer">
        {props.privateChatNotSupported ? (
          <div
            className="participant-left-placeholder"
            dangerouslySetInnerHTML={{
              __html: getFormattedString(
                t("PRIVATE_CHAT_NOT_SUPPORTED"),
                props.participant.name
              ),
            }}
          ></div>
        ) : props.participant.isLeft ? (
          <div
            className="participant-left-placeholder"
            dangerouslySetInnerHTML={{
              __html: getFormattedString(
                t("LEFT_THE_CHAT"),
                props.participant.name
              ),
            }}
          ></div>
        ) : (
          <React.Fragment>
            <div className={"message-box-container"+(!props.enableChatMessageBox ? " disable" : "")}>
            <Tooltip
              isOpen={isOpenToolTip}
              content={<span>{getFormattedString(
                t("ENTER_LESS_CHARACTERS"),
                MAX_MESSSAGE_LENGTH
              )}</span>}
              popoverClassName={Classes.INTENT_DANGER + " red-border"}
              position={Position.TOP}
            >
              <TextArea
                {...test("CHAT_MESSAGE_INPUT")}
                value={message}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                id={props.inputId}
                aria-label={""}
                aria-roledescription=""
                autoComplete="off"
                placeholder={t("TYPE_YOUR_MESSAGE") + "..."}
                disabled={!props.enableChatMessageBox}
                rows={1}
              />
            </Tooltip>
            <Button
              {...test("SEND_MESSAGE")}
              aria-label={t("SEND_MESSAGE")}
              disabled={!message}
              onClick={sendMessage}
              className="send-message"
              icon={
                <img
                  src={!message ? sendIcon : sendIconActive}
                  height={15}
                  width={18}
                  alt="icon"
                />
              }
            />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default MessageComposer;
