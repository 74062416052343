import React from "react";
import "./CallStatusMessage.scss";
import Logo from "../../assets/images/logos/Logo_white.svg";

const CallStatusMessage = (props) => {
  return (
    <div className="call-status-message-container">
      <div className="call-status-message">
        <div className="only-participant">
          <div className="call-status-message-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <p className="title">{props.title}</p>
          <p className="description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default CallStatusMessage;
