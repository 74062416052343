import React from "react";
import { useTranslation } from "react-i18next";
import {
  isWindows,
  isMacOs,
  isIOS,
  isIPad13,
  isAndroid,
  isMobile,
} from "react-device-detect";
import GoogleChromeIcon from "../../../../assets/images/icons/google-chrome-icon.png";
import SafariIcon from "../../../../assets/images/icons/safari-icon.png";
import BrowserLink from "../../../../components/UnSupported/BrowserIcon/BrowserIcon";

function BrowserUnsupported() {
  const { t } = useTranslation();

  const chromeLink = isMobile
    ? "https://play.google.com/store/apps/details?id=com.android.chrome&hl=uk&gl=US"
    : "https://www.google.com/intl/en_pk/chrome/";

  return (
    <>
      <p className="browser-support__title">
        <strong>{t("BROWSER_DOESNT_SUPPORT")}</strong>
      </p>
      <p className="browser-support__subtitle">
        {t("DOWNLOAD_ONE_OF_BROWSERS")}
      </p>
      <div className="browser-support__list">
        <div className="browser-support__list-box">
          {(isWindows || isAndroid || (isMacOs && !isIPad13)) && (
            <BrowserLink
              title={t("Download Chrome")}
              link={chromeLink}
              src={GoogleChromeIcon}
            />
          )}
          {(isMacOs || isIOS) && (
            <BrowserLink
              title={t("Download Safari")}
              link="https://support.apple.com/downloads/safari"
              src={SafariIcon}
            />
          )}
        </div>
      </div>
      <p className="browser-support__subtitle">
        {t("OR_DOWNLOAD_VIDYOCONNECT")}
      </p>
    </>
  );
}

export default BrowserUnsupported;
