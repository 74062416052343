import React, { memo, useEffect, useState } from "react";
import MessageComposer from "./MessageComposer";
import { Button } from "@blueprintjs/core";
import History from "./History";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isAndroid, isMobileSafari } from "react-device-detect";
import "./Chat.scss";
import ParticipantsList from "./ParticipantsList/ParticipantsList";
import { useDispatch } from "react-redux";
import hunterChat from "utils/hunterChat";
import { sendChatMessage, addMessageClass } from "store/actions/chat";
import { openPrivateChat, resetUnreadMessageCount } from "store/actions/chat";
import { OverlayToaster, Position } from "@blueprintjs/core";
import {test, getInitials, getFormattedString, unsafeParseTextFromHTMLString } from "utils/helpers";
import { useRef } from "react";

const Chat = (props) => {
  const inputId = "chat-inpit-id";
  const { t } = useTranslation();
  const isPrivateChatEnable = useSelector(
    (state) => state.config.portalFeatures?.["EndpointPrivateChat"]
  );
  const [showShadow, setShowShadow] = useState(false);
  const [privateChatNotSupported, setPrivateChatNotSupported] = useState(false);
  const [showHistory, setShowHistory] = useState(!isPrivateChatEnable);
  const [enableChatMessageBox ,setEnableChatMessageBox]=useState(true)
  let privateChatSupportArray = useRef([]);
  const dispatch = useDispatch();
  const specialMessageInfo = {
    specMessageClass: "MSGCLASS_VCSYSTEM",
    specMessageType: "MSGTYPE_PRIVATE",
  };
  let [particpantData, setParticipantData] = useState({
    id: "group",
    name: "GROUP_CHAT",
  });
  const unreadMessageCounter = useSelector(
    (state) => state.chat.unreadMessageCount
  );
  const openPrivateChatWindowFor = useSelector(
    (state) => state.chat.openPrivateChatWindowFor
  );
  const { specialMessage: chatSpecialMessage } = useSelector(
    (state) => state.chat
  );
  const participantCount = useSelector(
    (state) => state.call.participants.list.length
  );
  const BREAKOUT_ROOM = useSelector((state) => state.feature_breakoutRooms);
  const toasterRef = useRef(null);
  const messageRecieved = useSelector((state) => state.chat.messageRecieved);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const specialMessageRecieveTimer = useRef(null);
  useEffect(() => {
    let unreadMessage = 0;
    for (const id in unreadMessageCounter) {
      unreadMessage += unreadMessageCounter[id];
      setUnreadMessageCount(unreadMessage);
    }
  }, [unreadMessageCounter]);

  useEffect(() => {
    if (BREAKOUT_ROOM.isTransferInProgress) {
      setShowHistory(!isPrivateChatEnable);
      dispatch(resetUnreadMessageCount());
    }
  }, [BREAKOUT_ROOM.isTransferInProgress, isPrivateChatEnable, dispatch]);

  useEffect(() => {
    if (openPrivateChatWindowFor) {
      setPrivateChatNotSupported(false);
      checkCacheForPrivateChatSupport(openPrivateChatWindowFor);
      setParticipantData(openPrivateChatWindowFor);
      setShowHistory(true);
      if (!props.isChatOpen) {
        props.toggleChat();
      }
      dispatch(openPrivateChat(null));
    }
    // eslint-disable-next-line
  }, [openPrivateChatWindowFor]);

  useEffect(() => {
    if (props.isChatOpen && !(isMobileSafari || isAndroid)) {
      document.getElementById(inputId)?.focus();
    }
  }, [props.isChatOpen,showHistory]);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  };

  useEffect(() => {
    if (isPrivateChatEnable) {
      dispatch(addMessageClass("MSGCLASS_VCSYSTEM"));
    }
    // eslint-disable-next-line
  }, []);

  const checkCacheForPrivateChatSupport = (participant) => {
    if (privateChatSupportArray.current[participant.id] !== undefined) {
      if (!privateChatSupportArray.current[participant.id]) {
        setPrivateChatNotSupported(true);
        setEnableChatMessageBox(false);
      } else {
        setPrivateChatNotSupported(false);
        setEnableChatMessageBox(true);
      }
    } else {
      queryPrivateChatSupported(participant);
    }
  }

  const queryPrivateChatSupported = (participant) => {
    if (participant.id === "group") {
      setEnableChatMessageBox(true);
      return;
    }
    setEnableChatMessageBox(false);
    const SpecialMessageData = {
      type: "request", // possible values [request, response]
      privateChatSupported: true,
    };
    const Message = hunterChat.createMessage(
      SpecialMessageData,
      specialMessageInfo,
      null,
      true,
      "privateChatHandshake"
    );
    dispatch(
      sendChatMessage({
        message: Message,
        participant: participant,
      })
    );
    specialMessageRecieveTimer.current = setTimeout(() => {
      setPrivateChatNotSupported(true);
      privateChatSupportArray.current[participant?.id]=false;
    }, 2000);
  };

  const responsePrivateChatSupported = (chatSpecialMessage) => {
    const specialMessage = chatSpecialMessage?.message?.body;

    if (specialMessage) {
      const parsedMessage = hunterChat.parseSpecialMessage(specialMessage);

      if (parsedMessage?.specMessageBody?.includes?.("privateChatHandshake")) {
        const receivedData = hunterChat.parseFetureMessage(
          parsedMessage?.specMessageBody
        );
        if (receivedData?.type === "request") {
          const SpecialMessageData = {
            type: "response", // possible values [request, response]
            privateChatSupported: !!isPrivateChatEnable, // 0 - chat not supported, >= 1 - incremental chat protocol version. Always present for any type
          };
          const Message = hunterChat.createMessage(
            SpecialMessageData,
            specialMessageInfo,
            null,
            true,
            "privateChatHandshake"
          );
          dispatch(
            sendChatMessage({
              message: Message,
              participant: chatSpecialMessage.participant,
            })
          );
        }

        if (receivedData?.type === "response") {
          clearTimeout(specialMessageRecieveTimer.current);
          if (!receivedData?.privateChatSupported) {
            setPrivateChatNotSupported(true);
            setEnableChatMessageBox(false);
            privateChatSupportArray.current[chatSpecialMessage?.participant?.id]=false;
          }else{
            setPrivateChatNotSupported(false);
            setEnableChatMessageBox(true);
            privateChatSupportArray.current[chatSpecialMessage?.participant?.id]=true;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (chatSpecialMessage) {
      responsePrivateChatSupported(chatSpecialMessage);
    }
    // eslint-disable-next-line
  }, [chatSpecialMessage]);

  const participantItemClick = (item) => {
    if(!item.isLeft){
      checkCacheForPrivateChatSupport(item);
    }
    setParticipantData(item);
    setShowHistory(true);
  };

  const participantUpdated = (item) => {
    if (particpantData.id === item.id) {
      setParticipantData(item);
    }
    if(item.isLeft){
      delete privateChatSupportArray.current[item.id];
    }
  };

  useEffect(() => {
    if (messageRecieved && !props.isChatOpen) {
      toasterRef.current.show({
        message: getNotificationContent(),
        className: "chat-notification",
        timeout: 5000,
        isCloseButtonShown: false,
      });
    }
    // eslint-disable-next-line
  }, [messageRecieved]);

  useEffect(() => {
    if (props.isChatOpen) {
      toasterRef.current.clear();
    }
    // eslint-disable-next-line
  }, [props.isChatOpen]);

  const notificationClicked = (item) => {
    if (item) {
      toasterRef.current.clear();
      dispatch(openPrivateChat(item));
    }
  };

  useEffect(() => {
    const toastInstance = toasterRef?.current;
    return () => {
      toastInstance?.clear();
    };
  }, []);

  const getNotificationContent = () => {
    if (messageRecieved) {
      return (
        <section
          className="chat-messages-notification-cointainer"
          onClick={() => {
            if (messageRecieved.channelID === "group") {
              notificationClicked({ id: "group", name: "GROUP_CHAT" });
            } else {
              notificationClicked(messageRecieved.participant);
            }
          }}
        >
          <div className="chat-messages-notification is-visible">
            <div aria-hidden className="avatar" {...test("PARTICIPANT_AVATAR")}>
              {getInitials(messageRecieved.participant.name)}
            </div>
            <div className="chat-notification-content" role="alert">
              {messageRecieved.message.type === "VIDYO_CHATMESSAGETYPE_Chat" ? (
                <div
                  className="participant-name"
                  dangerouslySetInnerHTML={{
                    __html: getFormattedString(
                      t("CONFRENCE_MESSAGE"),
                      messageRecieved.participant.name
                    ),
                  }}
                ></div>
              ) : (
                <div
                  className="participant-name"
                  dangerouslySetInnerHTML={{
                    __html: getFormattedString(
                      t("PRIVATE_MESSAGE"),
                      messageRecieved.participant.name
                    ),
                  }}
                ></div>
              )}
              <div className="message-text">{messageRecieved.message.body}</div>
            </div>
          </div>
        </section>
      );
    } else {
      return null;
    }
  };

  let unsafeName = unsafeParseTextFromHTMLString(particpantData.name);

  return (
    <section className={`chat ${props.isChatOpen ? "open" : ""}`}>
      <OverlayToaster
          ref={toasterRef}
          portalClassName="chat-portal"
          className="chat-toaster"
          position={Position.BOTTOM_RIGHT}
        ></OverlayToaster>
        <div className="chat-header-wrapper">
      <div
        className={
          "chat-header" +
          (showShadow ? " show-shadow" : "") +
          (showHistory ? "" : " chat-participant-list") +
          (isPrivateChatEnable ? "" : " group-chat-header")
        }
      >
        {showHistory ? (
          <React.Fragment>
            {isPrivateChatEnable && (
              <Button
                aria-label={`${t("BACK_CHAT_PARTICIPANT")}`}
                className="mobile-chat-back-to-participant-list"
                onClick={() => {
                  setPrivateChatNotSupported(false);
                  setShowHistory(false);
                }}
              >
                {unreadMessageCount ? (
                  <div
                    role="alert"
                    aria-live="polite"
                    aria-relevant="all"
                    tabIndex="-1"
                    aria-label={t("UREAD_MESSAGES") + " " + unreadMessageCount}
                    className="unread-message-counter"
                  >
                    <span aria-hidden>
                      {unreadMessageCount < 10 ? unreadMessageCount : "9+"}
                    </span>
                  </div>
                ) : null}
              </Button>
            )}
            <div className="chat-label-block">
              <div className="chat-label">
                {(particpantData.id === "group") ? t(particpantData.name) : unsafeName}
              </div>
              <div className="chat-label-info">
                {particpantData.id !== "group"
                  ? t("PRIVATE_CHAT")
                  : participantCount + " " + t("PARTICIPANTS").toLowerCase()}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="chat-label">{t("CHATS")}</div>
        )}

        <Button
          aria-label={`${t("CHAT")} ${t("TOGGLE")}`}
          className="mobile-chat-toggle"
          onClick={() => {
            props.toggleChat();
          }}
        />
      </div>
      </div>
      <div
        aria-hidden={props.isChatOpen ? "false" : "true"}
        aria-live="polite"
        aria-relevant="additions text"
        className={
          "chat-content" +
          (!showHistory ? " participant-block-chat-content" : "")
        }
      >
        <div className={!showHistory ? "participant-block" : "is-hidden"}>
          <ParticipantsList
            participantItemClick={participantItemClick}
            participantUpdated={participantUpdated}
            isChatOpen={props.isChatOpen}
          ></ParticipantsList>
        </div>
        {showHistory && (
          <React.Fragment>
          <div className={"history-block"}>

            <History scrollEvent={handleScroll} id={particpantData.id} isChatOpen={props.isChatOpen} />
          </div>
          </React.Fragment>
        )}
      </div>
      {showHistory && (
        <div
          aria-hidden={props.isChatOpen ? "false" : "true"}
          className="chat-footer"
        >
          <MessageComposer
            inputId={inputId}
            participant={particpantData}
            privateChatNotSupported={privateChatNotSupported}
            enableChatMessageBox = {enableChatMessageBox}
           />
        </div>
      )}
    </section>
  );
};

export default memo(Chat);
