import React from "react";
import "./LinkWithIcon.scss";

function LinkWithIcon({ link, src, title, clickHandler }) {
  return (
    <a
      href={link}
      className="icon-link"
      target="_blank"
      rel="noopener noreferrer"
      onClick={clickHandler}
    >
      <div className="icon-link__inner">
        <div className="icon-link__icon">
          <img src={src} alt={title} />
        </div>
        <span>{title}</span>
      </div>
    </a>
  );
}

export default LinkWithIcon;
