import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { cameraTurnOn, cameraTurnOff } from "store/actions/devices";
import storage from "../../utils/storage";
import useBackgroundEffect, {
  getIsBackgroundEffectSupported,
} from "utils/useBackgroundEffect";
import CameraEffectsSelectorComponent from "./CameraEffectsSelectorComponent";
import { isMobile } from "react-device-detect";
import useBanubaTokenValid from "../../utils/hooks";

const CameraEffectsSelector = ({ showSelfView }) => {
  const { t } = useTranslation();
  const [isBanubaTokenValid] = useBanubaTokenValid();
  const [backgroundEffectAvailable] = useBackgroundEffect();
  const [customEffects, setCustomEffects] = useState([]);
  const [selectedEffect, selectEffect] = useState(null);
  const [loadingBanubaPlugginInProgress, setLoadingBanubaPlugginInProgress] =
    useState(window.banubaIsLoaded && !window.banubaPluginReady);

  const isCameraTurnedOn = useSelector(
    (state) => state.devices.isCameraTurnedOn
  );
  const selectedCamera = useSelector((state) => state.devices.selectedCamera);
  const dispatch = useDispatch();

  const predefinedEffects = [1, 2, 3, 4, 5, 6, 7].map((i) => {
    return {
      id: `predefinedImage${i}`,
      name: `camera_bg_${i}`,
      preview: `images/camera_bg_preview/camera_bg_${i}.jpg`,
      label: t(`CAMERA_BG_IMAGE_${i}`),
    };
  });

  useEffect(() => {
    if (storage.getItem("selectedCameraEffect")) {
      const storedEffect = storage.getItem("selectedCameraEffect");

      if (storedEffect.id === "blur") {
        selectEffect({ id: "blur" });
      } else {
        selectEffect(storedEffect);
      }
    } else {
      selectEffect(null);
    }
  }, []);

  useEffect(() => {
    if (backgroundEffectAvailable) {
      setLoadingBanubaPlugginInProgress(false);
    }
  }, [backgroundEffectAvailable]);

  const onEffectSelected = async (effect) => {
    if (effect) {
      console.warn(`Selected effect ${effect.id}`);
      storage.removeItem("clearCameraEffect");
      selectEffect(effect);
      if (
        getIsBackgroundEffectSupported() &&
        !window.banubaIsLoaded &&
        !loadingBanubaPlugginInProgress
      ) {
        setLoadingBanubaPlugginInProgress(true);
        import("../../banuba/BanubaPlugin").then((banubaPlugin) => {
          banubaPlugin.initBanubaPlugin();
        });
        window.banubaIsLoaded = true;
      }
      if (effect.id === "blur") {
        storage.addItem("selectedCameraEffect", { id: "blur" });
      } else {
        storage.addItem("selectedCameraEffect", effect);
      }

      const applyBanubaEffect = async (event) => {
        setLoadingBanubaPlugginInProgress(false);

        const isBanubaInited = window.banuba?.isBanubaInited;
        if (!event) {
          if (effect.id === "blur") {
            await window.banuba.applyEffect("blur");
          } else {
            await window.banuba.applyEffect(effect.name);
          }
        }
        // skip aplying effect after loading the lib, such as effect will be applying during lib loading according to storege selectedCameraEffect
        if (!isBanubaInited || event) {
          if (selectedCamera && isCameraTurnedOn) {
            // reset local camera
            dispatch(cameraTurnOff({ selectedCamera }));
            setTimeout(() => {
              dispatch(cameraTurnOn({ selectedCamera }));
            }, 600);
          } else {
            // reset self-view
            // TODO find better solution to change a strem in self-view, currunt solution cause seflviw short blink than shift UI
            showSelfView(false);
            setTimeout(() => showSelfView(true), 100);
          }
        }
      };

      if (window.banubaPluginReady) {
        applyBanubaEffect();
      } else if (window.banubaIsLoaded && !loadingBanubaPlugginInProgress) {
        window.addEventListener("BanubaPluginReady", applyBanubaEffect, false);
      }
    } else {
      console.warn(`Clear effect`);
      selectEffect(null);
      storage.addItem("clearCameraEffect", true);
      storage.removeItem("selectedCameraEffect");
      if (window.banubaIsLoaded) window.banuba?.clearEffect?.();
    }

    storage.removeItem("defaultPortalBackground");
  };

  const onEffectAdded = (base64) => {
    let id = `customImage${new Date().valueOf()}`;
    console.warn(`Custom effect ${id} added`);
    let effect = {
      id,
      preview: base64,
      path: base64,
      label: "Uploaded by user",
    };
    setCustomEffects([...customEffects, effect]);
    selectEffect(effect);
    storage.removeItem("defaultPortalBackground");
  };

  const onEffectAddedError = (error) => {
    console.error("Custom effect was not added:", error);
  };

  const onEffectRemoved = (effect) => {
    console.warn(`Removed custom effect effect ${effect.id}`);
    setCustomEffects(customEffects.filter((e) => e.id !== effect.id));
    if (selectedEffect && selectedEffect.id === effect.id) {
      onEffectSelected(null);
    }
  };

  return (
    <>
      {((isMobile && isBanubaTokenValid !== false) || isBanubaTokenValid) && (
        <React.Fragment>
          {((isMobile && getIsBackgroundEffectSupported()) ||
            backgroundEffectAvailable) && (
            <CameraEffectsSelectorComponent
              disabledReason={""}
              customEffects={customEffects}
              predefinedEffects={predefinedEffects}
              selectedEffect={selectedEffect}
              onEffectSelected={onEffectSelected}
              onEffectAdded={onEffectAdded}
              onEffectAddedError={onEffectAddedError}
              onEffectRemoved={onEffectRemoved}
            />
          )}
          {loadingBanubaPlugginInProgress &&
            getIsBackgroundEffectSupported() && (
              <span>{t("LOADING_BLUR_EFFECT")}</span>
            )}
        </React.Fragment>
      )}
    </>
  );
};

export default CameraEffectsSelector;
