import {
  isSafari,
  isChrome,
  isEdge,
  fullBrowserVersion,
  isIOS,
  isAndroid,
  isMobile,
  isMacOs,
  isIPad13,
  osVersion,
  browserVersion,
} from "react-device-detect";

const searchParams = new URLSearchParams(window.location.search);
const skipBrowserCheck = searchParams.get("skipBrowserCheck");

const supportedBrowsers = {
  desktop: {
    chrome: {
      minVersion: "83",
    },
    safari: {
      minVersion: 14,
    },
    edge: {
      minVersion: "79",
    },
  },
  ios: {
    minVersion: "14",
    safari: {
      minVersion: 14,
    },
  },
  android: {
    minVersion: "8",
    chrome: {
      minVersion: "85",
    },
  },
};

let osNotSupported = false;
let browserNotSupported = true;

/**
 * Compares two arrays as version (compares all array elements separately)
 *
 * Return '>' if arr1 > arr2
 * Return '<' if arr1 < arr2
 * Return '=' if arr1 === arr2
 *
 * @param {Array} arr1
 * @param {Array} arr2
 */
function compareArray(arr1, arr2) {
  const diff = arr1.length - arr2.length;
  const addArr = new Array(Math.abs(diff)).fill(0);
  let localArr1 = [...arr1];
  let localArr2 = [...arr2];

  if (diff < 0) {
    localArr1 = localArr1.concat(addArr);
  } else if (diff > 0) {
    localArr2 = localArr2.concat(addArr);
  }

  localArr1 = localArr1.map((item) => parseInt(item, 10));
  localArr2 = localArr2.map((item) => parseInt(item, 10));

  for (let i = 0; i < localArr1.length; i++) {
    if (localArr1[i] !== localArr2[i]) {
      if (localArr1[i] > localArr2[i]) {
        return ">";
      } else if (localArr1[i] < localArr2[i]) {
        return "<";
      }
    }
  }
  return "=";
}

/**
 * Compares two App versions
 * returns "true" if app isn't supported (current App version is smaller than supported App version)
 * else return "false"
 *
 * @param {Number/String} currentVersion
 * @param {Number/String} supportedVersion
 */
function isNotSuported(currentVersion, supportedVersion) {
  const currentVParts = currentVersion.toString().split(".");
  const supportedVParts = supportedVersion.toString().split(".");

  const compareResult = compareArray(currentVParts, supportedVParts);

  if (compareResult === "<") {
    return true;
  }

  return false;
}

if (isMobile && !(isMacOs || isIPad13)) {
  if (
    (!isIOS && !isAndroid) ||
    (isIOS && isNotSuported(osVersion, supportedBrowsers.ios.minVersion)) ||
    (isAndroid &&
      isNotSuported(osVersion, supportedBrowsers.android.minVersion))
  ) {
    osNotSupported = true;
  }
}

if (isSafari && !isIOS) {
  browserNotSupported = isNotSuported(
    fullBrowserVersion,
    supportedBrowsers.desktop.safari.minVersion
  );
}

if (isIOS) {
  browserNotSupported = isNotSuported(
    browserVersion,
    supportedBrowsers.ios.safari.minVersion
  );
}

if (isIOS && !isSafari) {
  browserNotSupported = true;
}

if (isChrome && !isMobile) {
  browserNotSupported = isNotSuported(
    fullBrowserVersion,
    supportedBrowsers.desktop.chrome.minVersion
  );
}

if (isEdge && !isMobile) {
  browserNotSupported = isNotSuported(
    fullBrowserVersion,
    supportedBrowsers.desktop.chrome.minVersion
  );
}

if (isChrome && isAndroid) {
  browserNotSupported = isNotSuported(
    fullBrowserVersion,
    supportedBrowsers.android.chrome.minVersion
  );
}

if (!navigator.mediaDevices) {
  browserNotSupported = true;
}

if (skipBrowserCheck === "true" || skipBrowserCheck === "1") {
  if (browserNotSupported) {
    console.warn(
      `Browser is not supported, but "skipBrowserCheck" parameter present in the URL`
    );
  }
  browserNotSupported = false;
}

const isIOS15 = isIOS && parseInt(osVersion, 10) === 15;

export { browserNotSupported, isIOS15, osNotSupported };
